import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';

const ANALYTICS_COOKIE_GROUP = 'C0002';

export enum ApiKeys {
  VERILY_ME = '3ba73f18-cbae-4a39-62df-c4dc168e7e32',
  VERILY_ENTERPRISE = '58c799cc-5f83-415f-4e30-ea53b9326a96',
  ENRICHMENT = '8ad7a399-a321-4ad8-7c68-29416238ef59',
  ENROLLMENT = 'e95fb36c-292d-49e8-49e7-6b06997bac04',
}

const appSuiteAPIKeyMap: Map<ApplicationSuite, string> = new Map([
  [ApplicationSuite.ENROLLMENT, ApiKeys.ENROLLMENT],
  [ApplicationSuite.VERILY_ENTERPRISE, ApiKeys.VERILY_ENTERPRISE],
  [ApplicationSuite.VERILY_ME, ApiKeys.VERILY_ME],
  // Enrichment does not have an application api.
]);

const apiKeyMap: Map<string, string> = new Map([
  ['me', ApiKeys.VERILY_ME],
  ['enterprise', ApiKeys.VERILY_ENTERPRISE],
  ['enrichment', ApiKeys.ENRICHMENT],
  ['enrollment', ApiKeys.ENROLLMENT],
]);

const anonymousIdKey = 'verily-pendo-anon-id';

const testConstants = {
  anonymousId: 'abcd-1234-abcd-1234-abcd',
};

export {
  ANALYTICS_COOKIE_GROUP,
  appSuiteAPIKeyMap,
  apiKeyMap,
  anonymousIdKey,
  testConstants,
};
