// TODO: Move this file to phaf-runtime-helpers.
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';

/**
 * Wrapped console.log function that logs to the console only if DEBUG_LOGS is set to true in the config.
 */
function log(msg: any) {
  if (config.get('DEBUG_LOGS')) {
    console.log(msg);
  }
}

/**
 * Wrapped console.warn function that logs to the console only if DEBUG_LOGS is set to true in the config.
 */
function warn(msg: any) {
  if (config.get('DEBUG_LOGS')) {
    console.warn(msg);
  }
}
export {log, warn};
