import {anonymousIdKey} from '../../constants';

/**
 * Checks local storage for anon id. If it doesn't exist, generates a new one.
 * @returns A unique visitor ID. This ID is stored in browser-local storage and reused on repeat visits if available.
 */
export function getVisitorId() {
  const existingAnonId = localStorage.getItem(anonymousIdKey);
  if (existingAnonId) {
    return existingAnonId;
  }
  // This is only ran in the browser, so we can use window.crypto.randomUUID() to generate a UUID.
  const anonId = window.crypto.randomUUID();
  // This UUID will be stored in browser-local storage and reused on repeat visits if available.
  localStorage.setItem(anonymousIdKey, anonId);

  return anonId;
}
