import {warn} from '@verily-src/phaf-runtime-helpers';
import {type VisitorMetaRecord} from '../types';

const textEncoder = new TextEncoder();

class PendoValidator {
  /**
   * Validates metadata based on pendo rules:
   * https://support.pendo.io/hc/en-us/articles/21326198721563-Choose-IDs-and-metadata
   * @param {Record} visitorMeta - provided visitor id
   */
  static isValidMetadata(visitorMeta: VisitorMetaRecord | undefined): boolean {
    // Check if defined and not empty.
    // We don't expect the visitorMeta to be long so this check is fine.
    if (visitorMeta && Object.keys(visitorMeta).length > 0) {
      for (const key in visitorMeta) {
        if (
          key.includes(' ') ||
          !isNaN(parseInt(key[0])) ||
          !this.containsOnlyDigitLetterUnderscore(key)
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * Validates provided visitor id based on pendo rules
   * @param {string} id - provided visitor id
   */
  static isValidId(id: string): boolean {
    if (id) {
      // Have to convert to bytes to accurately check length.
      if (textEncoder.encode(id).length > 128) {
        warn('Visitor id should not be longer than 128 bytes');
        return false;
      }
      if (id.includes('"') || id.includes('\\')) {
        warn('Visitor id should not include character \\ or "');
        return false;
      }
      return true;
    } else {
      warn('Visitor id should not be null or empty');
      return false;
    }
  }

  /**
   * Validates the input only includes letters, digits and underscores using regex:
   * @param {string} key - string input
   */
  private static containsOnlyDigitLetterUnderscore = (key: string): boolean => {
    return /^[a-zA-Z0-9_]+$/i.test(key);
  };
}

export {PendoValidator};
