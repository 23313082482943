/* eslint-disable no-var */
export {log, warn} from './log/log';
export {config} from './mfe_helpers/configurationWrapper';
export {startPHAF, type StartPHAFConfig} from './mfe_helpers/startPhaf';
export {setPublicPath} from './webpack_helpers/setPublicPath';
// ApplicationSuite enum is used in Unified API interface and is imported by all utility MFEs.
export enum ApplicationSuite {
  VERILY_ME,
  VERILY_ENTERPRISE,
  ENROLLMENT,
}
/**
 * Although these variables are defined in build-helpers. We have to redefine
 * them here as we cannot have a dependency on build helpers without adding
 * polyfill support for webpack 5 and dealing with type issues.
 */
declare global {
  // These need to be var so they are not block scoped.
  var MFE_NAME: string;
  var RELATIVE_ASSET_PATH: string;
  var __system_context__: {meta?: {url?: string}};
  var PHAF_INJECTED_VALUES: Record<string, string | number | boolean>;
}
