import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import crypto from 'crypto';
import {
  loadPendoAgent,
  pendoIdentify,
  pendoInitialize,
  pendoIsReady,
  pendoTeardown,
  pendoTrack,
} from './pendoLoader';
const ANALYTICS_COOKIE_GROUP = 'C0002';
// Agent can be initialized with optional visitor and account metadata
// per https://support.pendo.io/hc/en-us/articles/21362607464987-Components-of-the-install-script#01H6S2EXETNT7G60BJGG29PVYS?
export type InitUserAnalyticsOpts = {
  // Initialize visitor with optional metadata.
  // 6 types are supported: https://support.pendo.io/hc/en-us/articles/360038273392-Data-mapping-and-data-types-in-Pendo
  visitorMeta?: Record<
    string,
    string | number | boolean | Date | Array<string | number | boolean | Date>
  >;
  apiKey?: string;
};

export enum ApiKeys {
  VERILY_ME = '3ba73f18-cbae-4a39-62df-c4dc168e7e32',
  VERILY_ENTERPRISE = '58c799cc-5f83-415f-4e30-ea53b9326a96',
  ENRICHMENT = '8ad7a399-a321-4ad8-7c68-29416238ef59',
  ENROLLMENT = 'e95fb36c-292d-49e8-49e7-6b06997bac04',
}

const appSuiteAPIKeyMap: Map<ApplicationSuite, string> = new Map([
  [ApplicationSuite.ENROLLMENT, ApiKeys.ENROLLMENT],
  [ApplicationSuite.VERILY_ENTERPRISE, ApiKeys.VERILY_ENTERPRISE],
  [ApplicationSuite.VERILY_ME, ApiKeys.VERILY_ME],
  // Enrichment does not have an application api.
]);

/**
 * Returns API key based on provided application suite.
 */
export function getApiKey(appSuite: ApplicationSuite): string | undefined {
  const apiKey = appSuiteAPIKeyMap.get(appSuite);
  if (!apiKey) {
    console.warn('Pendo API key not found for application suite: ' + appSuite);
    return;
  }
  return apiKey;
}

class Analytics {
  private ANON_ID_KEY = 'verily-pendo-anon-id';
  private isInitialized = false;
  private apiKeyMap: Map<string, string> = new Map([
    ['me', ApiKeys.VERILY_ME],
    ['enterprise', ApiKeys.VERILY_ENTERPRISE],
    ['enrichment', ApiKeys.ENRICHMENT],
    ['enrollment', ApiKeys.ENROLLMENT],
  ]);

  private handleOneTrustChange!: () => void;
  private visitorIdFn = () => {
    const existingAnonId = localStorage.getItem(this.ANON_ID_KEY);
    if (existingAnonId) {
      return existingAnonId;
    }
    // "window.crypto" is for browsers, "crypto" is the equivalent node package when the browser API isn't available.
    const anonId =
      typeof window?.crypto?.randomUUID !== 'undefined'
        ? window.crypto.randomUUID()
        : crypto.randomUUID();
    // This UUID will be stored in browser-local storage and reused on repeat visits if available.
    localStorage.setItem(this.ANON_ID_KEY, anonId);

    return anonId;
  };

  /**
   * @deprecated
   * Returns the API key based on the current URL path.
   */
  private get apiKeyByCurrentPath() {
    const url = window.location.pathname;
    const appSuite = url.split('/')[1].toLowerCase();
    return this.apiKeyMap.get(appSuite);
  }

  private pendoReady = (): boolean => {
    return Boolean(pendoIsReady());
  };

  private isOneTrustConsentGranted = (): boolean => {
    return (window as any).OnetrustActiveGroups !== undefined
      ? (window as any).OnetrustActiveGroups.includes(ANALYTICS_COOKIE_GROUP)
      : false;
  };

  /**
   * Event handler for OneTrust event
   * @param {Analytics} self - it is needed for passing this (Analytics) object
   * @param {InitUserAnalyticsOpts} opts - pendo meta data
   */
  private OneTrustEventHandler(
    self: Analytics,
    opts?: InitUserAnalyticsOpts
  ): void {
    const isPendoReady = self.pendoReady();
    // If OnetrustActiveGroups has the correct cookie group (user consented) and
    // pendo is not initialized already, we initialize it.
    // If OnetrustActiveGroups does not have the correct cookie group (user did not consent)
    // and pendo is already initialized, we teardown pendo.
    // Otherwise we do nothing.
    if (self.isOneTrustConsentGranted() && !isPendoReady) {
      let visitorMeta = undefined;
      if (opts?.visitorMeta && opts.visitorMeta['id']) {
        throw new Error('Explicitly setting visitor ID is not yet supported.');
      }
      visitorMeta = opts?.visitorMeta;
      pendoInitialize(self.visitorIdFn(), visitorMeta);
    } else if (!self.isOneTrustConsentGranted() && isPendoReady) {
      pendoTeardown();
    }
  }

  /**
   * Adds OneTrust event listener to window object
   * @param {InitUserAnalyticsOpts} opts - pendo meta data
   */
  private addEventListener(opts?: InitUserAnalyticsOpts) {
    // This is needed for correctly passing this keyword referring to analytics object
    // Otherwise this keyword in the handler will refer to handler function
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.handleOneTrustChange = this.OneTrustEventHandler.bind(
      null,
      self,
      opts
    );
    window.addEventListener('OneTrustGroupsUpdated', this.handleOneTrustChange);
  }

  /**
   * Removes OneTrust event listener from window object
   * This function is mainly needed for unit tests
   */
  private removeEventListener() {
    window.removeEventListener(
      'OneTrustGroupsUpdated',
      this.handleOneTrustChange
    );
  }

  /**
   * Initializes pendo based on enabledIdentifiableAnalytics
   * @param {boolean} enabledIdentifiableAnalytics - set by the caller of the analytics MFE
   * @param {InitUserAnalyticsOpts} opts - pendo meta data
   */
  initializeUserAnalytics = (
    enabledIdentifiableAnalytics = false,
    opts?: InitUserAnalyticsOpts
  ) => {
    if (this.isInitialized) {
      return;
    }

    // Default to provided apiKey, but fallback to the one derived from the URL.
    const apiKey = opts?.apiKey || this.apiKeyByCurrentPath;
    if (!apiKey) {
      return;
    }

    loadPendoAgent(apiKey);
    // In case feature flag and enabledIdentifiableAnalytics both are true
    // identifiable analytics should be performed. Otherwise we call pendo
    // without any metadata and gather anonymous analytics.
    // BDD_TEST_IDENTIFIABLE_ANALYTICS_OVERRIDE is only intended to control
    // the behavior when running bdd tests.
    if (
      config.getBoolean('FEATURE_IDENTIFIABLE_ANALYTICS_ENABLED') &&
      (enabledIdentifiableAnalytics ||
        config.getBoolean('BDD_TEST_IDENTIFIABLE_ANALYTICS_OVERRIDE'))
    ) {
      this.addEventListener(opts);
    } else {
      pendoInitialize(this.visitorIdFn());
    }
    this.isInitialized = true;
  };

  setOrganizationId = (organizationId: string) => {
    pendoIdentify(undefined, organizationId);
  };

  track = (name: string, metadata?: Map<string, string>) => {
    pendoTrack(name, metadata);
  };
}

export {Analytics, ANALYTICS_COOKIE_GROUP};
