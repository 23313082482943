import {type ImportV3} from '@verily-src/phaf-common-imports';
import {registerApplication, start} from 'single-spa';
import {constructLayoutEngine, constructRoutes} from 'single-spa-layout';
import {
  HTMLLayoutData,
  type RoutesConfig,
} from 'single-spa-layout/dist/types/isomorphic/constructRoutes';
import {config} from '../src/mfe_helpers/configurationWrapper';
/* eslint-disable no-var */
export {config} from './mfe_helpers/configurationWrapper';
export {setPublicPath} from './webpack_helpers/setPublicPath';
// ApplicationSuite enum is used in Unified API interface and is imported by all utility MFEs.
export enum ApplicationSuite {
  VERILY_ME,
  VERILY_ENTERPRISE,
  ENROLLMENT,
}
/**
 * Although these variables are defined in build-helpers. We have to redefine
 * them here as we cannot have a dependency on build helpers without adding
 * polyfill support for webpack 5 and dealing with type issues.
 */
declare global {
  // These need to be var so they are not block scoped.
  var MFE_NAME: string;
  var RELATIVE_ASSET_PATH: string;
  var __system_context__: {meta?: {url?: string}};
  var PHAF_INJECTED_VALUES: Record<string, string | number | boolean>;
}

/**
 * @param {ImportV3[]} importList - imports to resolve eagerly during root configuration startup.
 */
export type StartPHAFConfig = {
  favicon: string;
  // List of MFEs being imported
  eagerImportList: Map<string, string>;
  mfeImportList: ImportV3[];
  data: HTMLLayoutData;
  layout: RoutesConfig;
  apps: Array<
    import('single-spa').RegisterApplicationConfig &
      import('single-spa-layout').WithLoadFunction
  >;
};

/**
 * Imports MFEs that are passed and NOT guarded by a feature flag or MFEs that have a true feature flag
 * Feature flag format should be {mfe name in upper case}_ENABLED. Example: REACT_EXAMPLE_MFE_ENABLED
 * @param {ImportV3} importList list of imported MFEs
 */
function importMFEs(importList: Map<string, string>) {
  importList.forEach((featureFlag: string, mfeName: string) => {
    if (
      config.get(featureFlag) === undefined ||
      config.getBoolean(featureFlag)
    ) {
      System.import(mfeName);
    }
  });
}

/**
 * Generates feature flag map based on MFE import list
 * Feature flag format should be FEATURE_{mfe name in upper case}_ENABLED. Example: FEATURE_PHAF_REACT_EXAMPLE_ENABLED
 * @param {ImportV3} importList list of imported MFEs
 */
export function generateFFMap(importList: ImportV3[]) {
  const featureFlagMap = new Map<string, boolean>();
  importList.forEach(mfe => {
    // routes should not be included in feature flag map
    if (!mfe.key().includes('/routes')) {
      const mfeName = mfe.mfeName().toString();
      // We expect the mfeName value to be kebab-case.
      const featureFlag =
        'FEATURE_' + mfeName.split('-').join('_').toUpperCase() + '_ENABLED';
      if (config.get(featureFlag) !== undefined) {
        featureFlagMap.set(mfe.key(), config.getBoolean(featureFlag));
      }
    }
  });
  return featureFlagMap;
}

export function getAuthConfig() {
  const domain = config.getString('DOMAIN');
  const clientId = config.getString('CLIENT_ID');
  const audience = config.getString('AUDIENCE');
  let redirectPathname = config.getString('REDIRECT_PATHNAME');
  const usingReactAuthMFE = config.getBoolean('ENABLE_REACT_AUTH_MFE') ?? false;
  const appName = config.getString('APP_NAME');

  // Validate configuration.
  if (!domain) {
    throw new Error('Auth MFE requires a configuration value for DOMAIN');
  }
  if (!clientId) {
    throw new Error('Auth MFE requires a configuration value for CLIENT_ID');
  }
  if (!audience) {
    throw new Error('Auth MFE requires a configuration value for AUDIENCE');
  }
  if (!redirectPathname) {
    throw new Error(
      'Auth MFE requires a configuration value for REDIRECT_PATHNAME'
    );
  }

  // Remove a single leading / if it is present in redirectPathname
  if (redirectPathname.startsWith('/')) {
    redirectPathname = redirectPathname.substring(1);
  }
  // The callback URL to redirect to after authentication.
  // Must be specified as a valid allowed callback URL in the Auth0 Application Settings.
  // See https://auth0.com/docs/get-started/authentication-and-authorization-flow/add-login-auth-code-flow#parameters
  const redirectUri = `${window.location.origin}/${redirectPathname}`;

  return {domain, clientId, audience, redirectUri, usingReactAuthMFE, appName};
}

/**
 * Starts a PHAF MFE by getting required configuration
 * @param {StartPHAFConfig} startPHAFConfig contains configuration needed for starting an MFE
 */
export function startPHAF(startPHAFConfig: StartPHAFConfig) {
  importMFEs(startPHAFConfig.eagerImportList);

  if (config.getBoolean('FEATURE_AUTH_ENABLED')) {
    const {
      domain,
      clientId,
      audience,
      redirectUri,
      usingReactAuthMFE,
      appName,
    } = getAuthConfig();

    System.import('@verily-src/auth').then(module => {
      const Auth = module.Auth;
      if (appName === '') {
        Auth.setUp({
          domain,
          clientId,
          redirectUri,
          audience,
          usingReactAuthMFE,
          enableAutoLogout: false,
        });
      } else {
        Auth.setUp({
          domain,
          clientId,
          redirectUri,
          audience,
          usingReactAuthMFE,
          enableAutoLogout: false,
          appName,
        });
      }
    });
  }

  // Construct routes & applications, passing in props data.
  // Route activation is set up using microfrontend layout route options.
  const routes = constructRoutes(startPHAFConfig.layout, startPHAFConfig.data);
  const applications = startPHAFConfig.apps;

  const layoutEngine = constructLayoutEngine({routes, applications});

  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
}
