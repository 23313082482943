import '@verily-src/phaf-runtime-helpers';
import {getApiKey} from './analytics/getApiKey';
import {Analytics} from './analytics/userAnalytics';
import {InitUserAnalyticsOpts} from './types';

const analytics = new Analytics();
const userAnalytics = analytics.initializeUserAnalytics;

export default userAnalytics;
export {analytics, type InitUserAnalyticsOpts, getApiKey};
