// This vendor-snippet downloads the Pendo Agent SDK, used for user analytics;
// you should only modify it if the vendor provides an updated version.
// See more details at: https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo
// and https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application

export function loadPendoAgent(apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://us1.cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
}

/**
 * Initializes Pendo with visitor metadata if set and enabled.
 */
export function pendoInitialize(visitorMetadata) {
  if (!visitorMetadata) {
    pendo.initialize();
  } else {
    pendo.initialize({visitor: {...visitorMetadata}});
  }
}

export function pendoTeardown() {
  pendo.teardown();
}

export function pendoIsReady() {
  return pendo.isReady();
}

/**
 * Calls pendo.identify() function to expose to the MFEs that require it
 * @param visitor - visitor object
 * Example: {id: 'visitorId'}
 */
export function pendoIdentify(visitor) {
  if (visitor) {
    pendo.identify({visitor: visitor});
  }
}

/**
 * Calls pendo.track() function to expose to the MFEs that require it
 * @param name - event name
 * @param metadata - additional metadata about the event
 */
export function pendoTrack(name, metadata) {
  pendo.track(name, metadata);
}
