import {ApplicationSuite, warn} from '@verily-src/phaf-runtime-helpers';
import {appSuiteAPIKeyMap} from '../constants';

/**
 * Returns API key based on provided application suite.
 */
export function getApiKey(appSuite: ApplicationSuite): string | undefined {
  const apiKey = appSuiteAPIKeyMap.get(appSuite);
  if (!apiKey) {
    warn('Pendo API key not found for application suite: ' + appSuite);
    return;
  }
  return apiKey;
}
